import { HospitalBase } from './Data/Hospital';
import { joinArgs } from './Utils/arrayUtils';

export const dictionary = Object.freeze({
  // Approval Routes
  APPROVAL_ROUTES: 'Approvals',
  APPROVAL_ROUTES_HOSPITAL_LABEL: 'Hospital',
  APPROVAL_ROUTES_DEPARTMENT_LABEL: 'Department',
  APPROVAL_ROUTES_HEADER_LABEL: 'All Approval Routings',
  APPROVAL_ROUTES_NEW_BUTTON_LABEL: 'Create New Approval Routing',
  APPROVAL_ROUTES_NEW_HEADER_LABEL: 'Create Approval Routing',
  APPROVAL_ROUTES_EDIT_HEADER_LABEL: 'Edit Approval Routing',
  APPROVAL_ROUTES_THRESHOLD_ERROR: 'Must be between 1 and 500,000',
  APPROVAL_ROUTES_WHOLE_DOLLAR_ERROR: 'Only whole dollar amounts allowed',
  APPROVAL_ROUTES_SECOND_GREATER_THAN_FIRST_ERROR: 'Must be greater than 1st approver threshold',
  APPROVAL_ROUTES_ALREADY_EXISTS_ERROR: (hospital: string, department: string) =>
    `This approval routing (${hospital}, ${department}) already exists.`,

  // Business Rules
  BUSINESS_RULES_LIST_HEADER_LABEL: 'Business Rules',
  BUSINESS_RULES_CREATE_NEW_ACTION: 'Create Business Rules',
  BUSINESS_RULES_TABLE_EMPTY: 'There are no business rules loaded or added.\nCreate Business Rules to add rules for a hospital.',
  CREATE_BUSINESS_RULES_FORM_PAGE_HEADER: 'Create Business Rules',
  CREATE_BUSINESS_RULES_SUBMIT_BTN: 'Save Rules',
  CREATE_BUSINESS_RULES_SUCCESS: 'Business Rules were successfully created.',
  CREATE_BUSINESS_RULES_ERROR: 'Business Rules could not be created. Please try again. If the issue persists, please contact 877-774-4488.',
  EDIT_BUSINESS_RULES_FORM_PAGE_HEADER: (hosp: string) => `Edit ${hosp} Business Rules`,
  EDIT_BUSINESS_RULES_SUBMIT_BTN: 'Save Changes',
  EDIT_BUSINESS_RULES_SUCCESS: 'Business Rules were successfully updated.',
  EDIT_BUSINESS_RULES_ERROR: 'Business Rules could not be updated. Please try again. If the issue persists, please contact 877-774-4488.',
  BUSINESS_RULES_DETAILS_HEADER: (hosp: string) => `View ${hosp} Business Rules`,
  BUSINESS_RULES_EDIT_BUTTON: 'Edit Rules',
  BUSINESS_RULES_DETAILS_LOAD_ERR: 'There was an issue loading business rules for this department. Please try again. If the issue persists, please contact 877-774-4488.',
  HOSPITAL_BUSINESS_RULES_LOAD_ERR: 'There was an issue loading business rules for this hospital. Please try again. If the issue persists, please contact 877-774-4488.',
  BUSINESS_RULES_INTERFACE_TYPE: 'Interface Type',
  BUSINESS_RULES_LOOKBACK_PERIOD_HEADER: 'Lookback Period',
  BUSINESS_RULES_ELIGIBILITY_DATE_HEADER: 'Eligibility Date',
  INTERFACE_TYPE_REQ_EXPORT: 'Requisition Export',
  RULE_NAME_ITEM_ID_REQUIRED: 'Item ID Required',
  RULE_NAME_COST_CENTER_REQUIRED: 'Cost Center Required',
  RULE_NAME_PRODUCT_CATEGORY_REQUIRED: 'Product Category Required',
  RULE_NAME_CONTRACT_ID_REQUIRED: 'Contract ID Required',
  RULE_NAME_MANUFACTURER_ID_REQUIRED: 'Vendor ID Required',
  ITEM_ID_REQUIRED_DESCRIPTION: 'Item ID required for all products on the requisition',
  COST_CENTER_REQUIRED_DESCRIPTION: 'Cost Center required for requisition approval',
  PRODUCT_CATEGORY_REQUIRED_DESCRIPTION: 'Product Category required for all products on the requisition',
  CONTRACT_ID_REQUIRED_DESCRIPTION: 'Contract ID required for all products on the requisition',
  MANUFACTURER_ID_REQUIRED_DESCRIPTION: 'Vendor ID required for requisition approval',
  ELIGIBILITY_DATE_DESCRIPTION: (date: string) => `Eligibility Date: ${date}`,
  LOOKBACK_PERIOD_DESCRIPTION: (n: number) => `Lookback Period: ${n} days`,
  HEADER_BUSINESS_RULES_FOR: (hospitalName: string) => `Business Rules for ${hospitalName}`,
  BUSINESS_RULES_EMPTY_STATE: 'No business rules to show.',

  // Capitation
  CAPITATION_CONTRACT_ID_LABEL: 'Contract ID',
  CAPITATION_CONTRACT_EXP_DATE_LABEL: 'Contract Exp. Date',
  CAPITATION_CONTRACT_EXP_DATE_LONG_LABEL: 'Contract Expiration Date',
  CAPITATION_CONTRACT_HOSPITAL_LABEL: 'Hospital(s)',
  CAPITATION_NAME_LABEL: 'Capitation Name',
  CAPITATION_NAME_SHORT_LABEL: 'Name',
  CAPITATION_MANUFACTURER_LABEL: 'Manufacturer',
  CAPITATION_CATEGORY_LABEL: 'Capitation Category',
  CAPITATION_ITEM_ID_LABEL: 'Capitation Item ID',
  CAPITATION_PRICE_LABEL: 'Capitation Price',
  CAPITATION_PRICE_SHORT_LABEL: 'Price',
  CAPITATIONS: 'Capitations',
  CAPITATIONS_LIST_HEADER_LABEL: 'Capitations',
  CAPITATIONS_NEW_HEADER_LABEL: 'Create a New Capitation',
  CAPITATIONS_EDIT_HEADER_LABEL: 'Edit Capitation:',
  CAPITATIONS_CREATE_ACTION: 'Create Capitation',
  CAPITATIONS_CREATE_NEW_ACTION: 'Create New Capitation',
  CAPITATIONS_NOT_AVAILABLE: 'No capitations available',
  CREATE_CAPITATION_SUCCESS: 'Capitation has been successfully created.',
  CREATE_CAPITATION_ERROR: 'There was a problem creating the capitation. Please try again. If the issue persists, please contact 877-774-4488.',
  UPDATE_CAPITATION_SUCCESS: 'Capitation has been updated.',
  UPDATE_CAPITATION_ERROR: 'Capitation could not be updated. Please try again. If the issue persists, please contact 877-774-4488.',

  // - Create/Edit Capitation
  CAPITATION_NO_VALID_CONTRACT_CHOSEN_ERR: 'Please select a contract',

  // Capitation Categories
  CAPITATION_CATEGORIES: 'Capitation Categories',
  CAPITATION_CATEGORIES_LIST_HEADER_LABEL: 'Capitation Categories',
  CAPITATION_CATEGORIES_TABLE_EMPTY: 'There are no capitation categories loaded or added. \n Create a Capitation Category to add a capitation category to Bill Only Management.',
  CAPITATION_CATEGORIES_CREATE_NEW_ACTION: 'Create Capitation Category',
  CAPITATION_CATEGORY_FORM_CATEGORY_NAME: 'Capitation Category',
  CAPITATION_CATEGORY_FORM_HOSPITAL: 'Hospital',
  CREATE_CAPITATION_CATEGORY_FORM_PAGE_HEADER: 'Create Capitation Category',
  CREATE_CAPITATION_CATEGORY_SUBMIT_BTN: 'Save Capitation Category',
  CREATE_CAPITATION_CATEGORY_SUCCESS: 'Capitation Category was successfully created.',
  CREATE_CAPITATION_CATEGORY_ERROR: 'There was a problem creating the capitation category. Please try again. If the issue persists, please contact 877-774-4488.',
  CREATE_CAPITATION_CATEGORY_UNIQUE_ERROR: (hospitals: HospitalBase[]) => (hospitals?.length === 1 ?
    `Capitation Category could not be created because ${hospitals[0].name} already has that capitation category.` :
    `Capitation Category could not be created because ${hospitals.map(h => h.name).join().replace(/,([^,]*)$/, (', and $1'))} already have that capitation category.`),
  EDIT_CAPITATION_CATEGORY_FORM_PAGE_HEADER: 'Edit Capitation Category',
  EDIT_CAPITATION_CATEGORY_SUCCESS: 'Capitation Category was successfully updated.',
  EDIT_CAPITATION_CATEGORY_LOAD_ERROR: 'There was an issue loading this capitation category. Please try again. If the issue persists, please contact 877-774-4488.',
  EDIT_CAPITATION_CATEGORY_ERROR: 'There was a problem updating the capitation category. Please try again. If the issue persists, please contact 877-774-4488.',
  EDIT_CAPITATION_CATEGORY_UNIQUE_ERROR: 'Capitation Category could not be updated because the hospital already has that capitation category.',

  // Contracts
  CONTRACTS: 'Contracts',
  CONTRACT_CREATE_ERROR: 'Unable to create the contract at this time. An error occurred. Please try again or contact us at 877-774-4488 if the issue persists.',
  CONTRACT_LOAD_ERROR: 'There was an issue loading this contract. Please try again. If the issue persists, please contact 877-774-4488.',
  CONTRACT_INFO_VALIDATION_REQUEST_FAILED_ERROR: 'There was an issue validating contract info. Please try again. If the issue persists, please contact 877-774-4488.',
  CONTRACT_IDENTIFIER_UNIQUENESS_ERROR: (names: string[]) =>
    `This Contract ID already exists for this manufacturer and ${names.join(', ')}. Please check your entry and try again.`,
  CONTRACT_EXCEPTION_MESSAGE: 'product(s) have outstanding exceptions and will not be added to the contract.',
  CONTRACT_UPDATE_EXCEPTION_MESSAGE: 'product(s) have outstanding exceptions and will be ignored when updating.',
  CONTRACT_EXCEPTIONAL_PRODUCTS_DOWNLOAD: 'Download Exceptions File to Adjust',
  CONTRACT_HAS_NO_PRODUCTS_ERROR_MESSAGE: 'You have not added any products to the contract. Please add at least one (1) to proceed.',
  CONTRACT_NEW_HEADER_LABEL: 'Create a Contract',
  CONTRACT_NEW_CONTRACT_INFO_STEPPER_LABEL: 'Contract Info',
  CONTRACT_NEW_PRODUCT_PRICING_LABEL: 'Create a Contract | Product Pricing Information',
  CONTRACT_NEW_PRODUCT_PRICING_STEPPER_LABEL: 'Product Pricing Info',
  CONTRACT_NEW_REVIEW_SUBMIT_LABEL: 'Review & Submit Contract ID:',
  CONTRACT_NEW_REVIEW_SUBMIT_STEPPER_LABEL: 'Review & Submit',
  CONTRACT_EDIT_HEADER_LABEL: 'Edit Contract',
  CONTRACT_INFORMATION_SECTION_HEADER: 'Contract Information',
  CONTRACT_TEMPLATE_BOX_LABEL: 'Add Product Pricing to Contract',
  CONTRACT_TEMPLATE_BOX_INSTRUCTIONS: 'Only .csv files are supported at this time.',
  CONTRACT_TEMPLATE_DOWNLOAD: 'Download template',
  CONTRACT_UPDATE_PRODUCTS: 'Update Contract Products',
  CONTRACT_EDIT_PRODUCTS_STEPPER_LABEL: 'Product Pricing Info',
  CONTRACT_REVIEW_AND_UPDATE_PRODUCTS_STEPPER_LABEL: 'Review and Update Products',
  CONTRACT_EDIT_PRODUCTS_BOX_LABEL: 'Edit Products on Contract',
  CONTRACT_PRODUCTS_DOWNLOAD_EXISTING: 'Download Products On Contract',
  CONTRACT_PRODUCTS_SUCCESSFULLY_ADDED_MESSAGE: 'product(s) successfully added to the contract.',
  CONTRACT_PRODUCTS_TOTAL_MESSAGE: 'product(s) total attached to contract.',
  CONTRACT_PRODUCTS_DELETE_MESSAGE: 'product(s) will be removed from contract.',
  CONTRACT_PRODUCTS_EXCEPTIONS_INSTRUCTIONS: 'product(s) could not be added. Please download the exceptions file to view and correct the errors or click proceed to move forward without adding the product(s).', // eslint-disable-line max-len
  CONTRACT_PRODUCTS_UPDATE_EXCEPTIONS_INSTRUCTIONS: 'product(s) could not be updated. Please download the exceptions file to view and correct the errors or click proceed to move forward without updating the product(s).', // eslint-disable-line max-len
  CONTRACT_TEMPLATE_UPLOAD_PRODUCTS: 'Upload File',
  CONTRACT_PRODUCTS_ON_CONTRACT_TABLE_HEADER: 'Products on Contract',
  CONTRACT_PRODUCTS_ON_CONTRACT: 'Number of Products:',
  CONTRACT_INDEX_PRODUCTS: 'No. of Products',
  CONTRACT_PRODUCTS_TABLE_EMPTY: 'There are no products loaded or added to this contract.',
  CONTRACTS_TABLE_EMPTY: 'No Contracts Available',
  CONTRACTS_INDEX_HEADER: 'Contracts',
  CONTRACTS_INDEX_ACTION: 'Create Contract',
  CONTRACT_SUBMIT_BUTTON_LABEL: 'Submit Contract',
  CONTRACT_UPDATE_BUTTON_LABEL: 'Update Contract',
  CONTRACT_DETAIL_PAGE_HEADER: (contractId?: string) => `Contract ${contractId}`,
  CONTRACT_HOSPITALS: 'Hospital(s)',
  CONTRACT_NUMBER_OF_PRODUCTS_LABEL: 'Products on Contract',
  CONTRACT_ID_LABEL: 'Contract ID',
  CONTRACT_MANUFACTURER_LABEL: 'Manufacturer',
  CONTRACT_EFFECTIVE_DATE_LABEL: 'Effective Date',
  CONTRACT_EXPIRATION_DATE_LABEL: 'Expiration Date',
  CONTRACT_WASTED_DISCOUNT_LABEL: 'Wasted Discount',
  CONTRACT_EXPLANT_DISCOUNT_LABEL: 'Explant Discount',
  CONTRACT_TRIAL_DISCOUNT_LABEL: 'Trial Discount',
  CONTRACT_WASTED_PRODUCT_DISCOUNT_LABEL: 'Wasted Product Discount',
  CONTRACT_EXPLANT_PRODUCT_DISCOUNT_LABEL: 'Explant Product Discount',
  CONTRACT_TRIAL_PRODUCT_DISCOUNT_LABEL: 'Trial Product Discount',
  CONTRACT_UPLOAD_ERR_PROD_CAT_ID_MISMATCH: 'Product Category ID must apply to all hospitals on the contract.',
  CONTRACT_HOSPITAL_REQUIRED_ERROR: 'Please select at least one hospital',
  CONTRACT_INVALID_EFFECTIVE_DATE: 'Invalid Effective Date',
  CONTRACT_INVALID_EXPIRATION_DATE: 'Invalid Expiration Date',
  CONTRACT_EFFECTIVE_DATE_BEFORE_EXPIRATION_ERROR: 'Date should not be before Effective Date',
  // CONTRACT_EFFECTIVE_DATE_OUT_OF_BOUNDS: 'Please enter a date no more than 600 days in the past or 1000 days in the future.',
  CONTRACT_CREATE_SUCCESS: 'Your contract is currently being processed.',
  CONTRACT_UPDATE_SUCCESS: 'Your contract has been successfully updated.',
  CONTRACT_UPDATE_PROCESSING: 'Your contract updates are currently being processed.',

  CONTRACT_STATUS_ACTIVE: 'Active',
  CONTRACT_STATUS_NOT_YET_ACTIVE: 'Not Yet Active',
  CONTRACT_STATUS_EXPIRED: 'Expired',
  CONTRACT_STATUS_EXPIRING_SOON: 'Expiring Soon',
  CONTRACT_STATUS_PROCESSING: 'Processing',
  CONTRACT_STATUS_LOADING: 'Loading',

  // Health Systems
  HEALTH_SYSTEMS_LIST_HEADER_LABEL: 'Health Systems',
  HEALTH_SYSTEM_NAME_LABEL: 'Health System',
  HEALTH_SYSTEMS_HOSPITALS_LABEL: 'Associated Hospitals',
  HEALTH_SYSTEMS_CREATE_NEW_ACTION: 'Create Health System',
  HEALTH_SYSTEM_TABLE_EMPTY: 'There are no health systems loaded or added. \n Create a Hospital to add a hospital to Bill Only Management.',
  CREATE_HEALTH_SYSTEM_SUCCESS: 'Health System was successfully created.',
  HEALTH_SYSTEM_NOT_UNIQUE_ERROR: 'A Health System with the entered name already exists.',
  CREATE_HEALTH_SYSTEM_ERROR: 'There was a problem creating the Health System. Please try again. If the issue persists, please contact 877-774-4488.',
  CREATE_HEALTH_SYSTEM_SUBMIT_BTN: 'Save Health System',
  CREATE_HEALTH_SYSTEM_PAGE_HEADER: 'Create Health System',
  HEALTH_SYSTEM_FORM_NAME: 'Health System Name',
  HEALTH_SYSTEM_EDIT_HEADER: 'Edit Health System',
  HEALTH_SYSTEM_DETAILS_LOAD_ERR: 'There was an issue loading this health system. Please try again. If the issue persists, please contact 877-774-4488.',
  HEALTH_SYSTEM_EDIT_SUCCESS: 'Health System was successfully updated.',
  HEALTH_SYSTEM_EDIT_FAILURE: 'Health System could not be updated. Please try again. If the issue persists, please contact 877-774-4488.',
  HEALTH_SYSTEMS_LOAD_ERROR: 'There was an issue loading health systems. Please try again. If the issue persists, please contact 877-774-4488.',

  // Hospitals
  HOSPITAL_EMPLOYEE_ID_LABEL: 'Hospital Employee ID',
  HOSPITAL_NAME_LABEL: 'Hospital',
  HOSPITAL_ID_LABEL: 'Hospital ID',
  HOSPITAL_EMAIL_LABEL: 'Contact Email',
  HOSPITAL_PHONE_LABEL: 'Contact Number',
  HOSPITAL_MESSAGE_LABEL: 'Contact Messaging',
  HOSPITAL_TABLE_EMPTY: 'There are no hospitals loaded or added. \n Create a Hospital to add a hospital to Bill Only Management.',
  HOSPITALS_LIST_HEADER_LABEL: 'Hospitals',
  HOSPITALS_CREATE_NEW_ACTION: 'Create Hospital',
  HOSPITAL_CONTACT_MSG_DEFAULT: 'Cannot find hospital specific info like physician or procedure?',
  // - Create Hospital
  CREATE_HOSPITAL_PAGE_HEADER: 'Create Hospital',
  CREATE_HOSPITAL_SUBMIT_BTN: 'Save Hospital',
  HOSPITAL_FORM_NAME: 'Hospital Name',
  HOSPITAL_FORM_CONTACT_EMAIL: 'Contact Email',
  HOSPITAL_FORM_CONTACT_PHONE: 'Contact Phone Number',
  HOSPITAL_FORM_CONTACT_MSG: 'Contact Message',
  CREATE_HOSPITAL_CONTACT_PREVIEW_LABEL: 'Contact Preview:',
  CREATE_HOSPITAL_ERROR: 'There was a problem creating the hospital. Please try again. If the issue persists, please contact 877-774-4488.',
  CREATE_HOSPITAL_SUCCESS: 'Hospital was successfully created.',
  HOSPITAL_NOT_UNIQUE_ERROR: 'A hospital with the entered name already exists.',
  // - Edit Hospital
  HOSPITAL_EDIT_HEADER: 'Edit Hospital',
  HOSPITAL_DETAILS_LOAD_ERR: 'There was an issue loading this hospital. Please try again. If the issue persists, please contact 877-774-4488.',
  HOSPITAL_EDIT_FAILURE: 'Hospital could not be updated. Please try again. If the issue persists, please contact 877-774-4488.',
  HOSPITAL_EDIT_SUCCESS: 'Hospital was successfully updated.',

  // Departments
  DEPARTMENT_NAME_LABEL: 'Department',
  DEPARTMENT_ID_LABEL: 'Department ID',
  DEPARTMENTS_LIST_HEADER_LABEL: 'Departments',
  DEPARTMENTS_CREATE_NEW_ACTION: 'Create Department',
  DEPARTMENTS_TABLE_EMPTY: 'There are no departments loaded or added.',
  DEPARTMENT_FORM_NAME: 'Department Name',
  DEPARTMENT_FORM_HOSPITAL: 'Hospital',
  DEPARTMENT_LOAD_ERR: 'There was an issue loading this department. Please try again. If the issue persists, please contact 877-774-4488.',

  // Create Department
  CREATE_DEPARTMENT_PAGE_HEADER: 'Create Department',
  CREATE_DEPARTMENT_SUBMIT_BTN: 'Save Department',
  CREATE_DEPARTMENT_SUCCESS: 'Department was successfully created.',
  CREATE_DEPARTMENT_ERROR: 'There was a problem creating the department. Please try again. If the issue persists, please contact 877-774-4488.',
  CREATE_DEPARTMENT_UNIQUE_ERROR: (hospitals: HospitalBase[]) => (hospitals?.length === 1 ?
    `Department could not be created because ${hospitals[0].name} already has that department.` :
    `Department could not be created because ${hospitals.map(h => h.name).join().replace(/,([^,]*)$/, (', and $1'))} already have that department.`),

  // Edit Department
  EDIT_DEPARTMENT_PAGE_HEADER: 'Edit Department',
  EDIT_DEPARTMENT_ERROR: 'Department could not be updated. Please try again. If the issue persists, please contact 877-774-4488.',
  EDIT_DEPARTMENT_SUCCESS: 'Department was successfully updated.',
  EDIT_DEPARTMENT_UNIQUE_ERROR: 'Department could not be updated because the hospital already has that department.',

  // Archive Department
  ARCHIVE_DEPARTMENT_BTN: 'Archive Department',
  ACTIVATE_DEPARTMENT_BTN: 'Activate Department',
  ARCHIVE_DEPARTMENT_SUCCESS: 'Department was successfully archived.',
  ACTIVATE_DEPARTMENT_SUCCESS: 'Department was successfully activated.',
  ARCHIVE_DEPARTMENT_ERROR: 'Department could not be updated.',
  ARCHIVE_DEPARTMENT_MODAL_HEADER: 'Archive this Department',
  ARCHIVE_DEPARTMENT_MODAL_TEXT: 'By archiving this department, it will no longer be available for selection on new requisitions. Existing requisitions will not be affected. \n\nArchived departments can be reactivated if needed. \n\nContinue to archive this department?', // eslint-disable-line max-len

  // Procedures
  PROCEDURE_NAME_LABEL: 'Procedure',
  PROCEDURES_LIST_HEADER_LABEL: 'Procedures',
  PROCEDURE_TABLE_EMPTY: 'There are no procedures loaded or added. \n Create a Procedure to add a procedure to a hospital.',
  PROCEDURES_CREATE_NEW_ACTION: 'Create Procedure',
  CREATE_PROCEDURE_PAGE_HEADER: 'Create Procedure',
  CREATE_EDIT_PROCEDURE_NAME: 'Procedure Name',
  CREATE_PROCEDURE_SUBMIT_BTN: 'Save Procedure',
  CREATE_PROCEDURE_SUCCESS: 'Procedure was successfully created.',
  CREATE_PROCEDURE_ERROR: 'Procedure could not be added.',
  PROCEDURE_NOT_UNIQUE_ERROR: 'A procedure with the entered name already exists.',
  PROCEDURE_DETAILS_LOAD_ERROR: 'There was an issue loading this procedure. Please try again. If the issue persists, please contact 877-774-4488.',
  PROCEDURES_EDIT_HEADER_LABEL: 'Edit Procedure',
  EDIT_PROCEDURE_SUCCESS: 'Procedure was successfully updated.',
  EDIT_PROCEDURE_ERROR: 'Procedure could not be updated. Please try again. If the issue persists, please contact 877-774-4488.',

  // Archive Procedure
  PROCEDURE_ARCHIVE_BTN: 'Archive Procedure',
  PROCEDURE_ACTIVATE_BTN: 'Activate Procedure',
  ARCHIVE_PROCEDURE_SUCCESS: 'Procedure was successfully archived.',
  ACTIVATE_PROCEDURE_SUCCESS: 'Procedure was successfully activated.',
  ARCHIVE_PROCEDURE_ERROR: 'Procedure could not be updated.',
  ARCHIVE_PROCEDURE_MODAL_HEADER: 'Archive this procedure',
  ARCHIVE_PROCEDURE_MODAL_TEXT: 'By archiving this procedure, it will no longer be available for selection on new requisitions. Existing requisitions will not be affected. \n\nArchived procedures can be reactivated if needed. \n\nContinue to archive this procedure?', // eslint-disable-line max-len

  // Products
  PRODUCTS: 'Products',
  PRODUCT_CATEGORY_NAME_LABEL: 'Product Category',
  PRODUCT_CATEGORIES_LIST_HEADER_LABEL: 'Product Categories',
  PRODUCT_CATEGORY_TABLE_EMPTY: 'There are no product categories loaded or added. \n Create a Product Category to add a product category to Bill Only Management.',
  PRODUCT_CATEGORY_IDENTIFIER_NAME_LABEL: 'Product Category ID',
  PRODUCT_CATEGORY_CREATE_NEW_ACTION: 'Create Product Category',
  PRODUCT_CATEGORY_LOADING_ERROR: 'There was an issue loading product categories. Please try again. If the issue persists, please contact 877-774-4488.',
  PRODUCT_CATEGORY_FORM_NAME: 'Product Category Name',
  CREATE_PRODUCT_CATEGORY_SUBMIT_BTN: 'Save Product Category',
  CREATE_PRODUCT_CATEGORY_PAGE_HEADER: 'Create Product Category',
  CREATE_PRODUCT_CATEGORY_SUCCESS: 'Product Category was successfully created.',
  CREATE_PRODUCT_CATEGORY_ERROR: 'Product Category could not be added.',
  EDIT_PRODUCT_CATEGORY_PAGE_HEADER: 'Edit Product Category',
  EDIT_PRODUCT_CATEGORY_LOAD_ERROR: 'There was an issue loading this product category. Please try again. If the issue persists, please contact 877-774-4488.',
  EDIT_PRODUCT_CATEGORY_SUCCESS: 'Product Category was successfully updated.',
  EDIT_PRODUCT_CATEGORY_FAILURE: 'Product Category could not be updated. Please try again. If the issue persists, please contact 877-774-4488.',
  PRODUCT_HOSPITAL: 'Hospital',
  PRODUCT_CONTRACT_ID: 'Contract ID',
  PRODUCT_MANUFACTURER: 'Manufacturer',
  PRODUCT_CATALOG_NUMBER: 'Catalog No.',
  PRODUCT_DESCRIPTION: 'Description',
  PRODUCT_CONTRACT_PRICE: 'Contract Price',
  PRODUCT_BRAND_NAME: 'Brand',
  PRODUCT_UNIT_OF_MEASURE: 'Unit of Measure',
  PRODUCT_QTY_UNIT_OF_MEASURE: 'Quantity Unit of Measure',
  PRODUCT_TYPE: 'Product Type',
  PRODUCT_ITEM_IDENTIFIER: 'Item ID',
  PRODUCT_SEARCH_PLACEHOLDER: 'Search All Products by Catalog No., Contract ID, Item ID, or Description',
  PRODUCT_SEARCH_HOSPITAL_PLACEHOLDER: 'All Hospitals',
  PRODUCT_SEARCH_CONTRACT_IDENTIFIER_PLACEHOLDER: 'All Contract IDs',
  PRODUCT_SEARCH_MANUFACTURER_PLACEHOLDER: 'All Manufacturers',
  PRODUCT_SEARCH_CLEAR_FILTERS_BTN: 'Clear Filter',
  PRODUCT_SEARCH_NULL_CONTRACT_IDENTIFIER_DISPLAY: 'Show Off-Contract Products',
  PRODUCT_SEARCH_RESULT_COUNT_EMPTY: 'No Result(s)',
  PRODUCT_SEARCH_RESULT_COUNT: (count?: number) => `${count} Result(s)`,
  PRODUCT_SEARCH_TABLE_BEGIN: 'Looking a little empty? \n Begin a search to find your product.',
  PRODUCT_SEARCH_TABLE_NO_RESULTS: (searchTerm: string) => `No results for "${searchTerm}". \n Double check the search for any typos or spelling errors.`,
  PRODUCT_SEARCH_ERROR: 'There was a problem searching for products. Please try again.',
  PRODUCT_DETAILS_LOAD_ERR: 'There was an issue loading this product. Please try again. If the issue persists, please contact 877-774-4488.',
  PRODUCT_DETAILS_HEADER: (catalogNumber: string) => `Product ${catalogNumber}`,
  PRODUCT_DETAILS_CONTRACT_INFO_HEADER: 'Contract Information',
  PRODUCT_DETAILS_PRODUCT_INFO_HEADER: 'Product Information',
  PRODUCT_DETAILS_BILLING_INFO_HEADER: 'Billing Information',
  PRODUCT_EDIT_BUTTON: 'Edit Product',
  PRODUCT_EDIT_HEADER: 'Edit Product',
  PRODUCT_EDIT_SUCCESS: 'Product was successfully updated.',
  PRODUCT_EDIT_FAILURE: 'Product could not be updated. Please try again. If the issue persists, please contact 877-774-4488.',
  PRODUCT_EDIT_FUTURE_CONTRACT_MSG: 'This product is being moved to a contract with an effective date in the future. Until that date, this product will be treated as off contract.' +
    '\n\n Do you wish to continue moving this product?',
  PRODUCT_EDIT_FUTURE_MODAL_TITLE: 'Move Product to Future Contract',
  PRODUCT_EDIT_EXPIRED_CONTRACT_MSG: 'This product is being moved to a contract that has expired and will be treated as off contract.\n\n Do you wish to continue moving this product?',
  PRODUCT_EDIT_EXPIRED_MODAL_TITLE: 'Move Product to Expired Contract',

  PRODUCT_CATALOG_NUMBER_NOT_UNIQUE_ERROR: 'This Catalog number is a duplicate and cannot be saved.',

  // Manufacturers
  MANUFACTURER_NAME_LABEL: 'Manufacturer',
  MANUFACTURER_ID_LABEL: 'Manufacturer ID',
  MANUFACTURERS_LIST_HEADER_LABEL: 'Manufacturers',
  MANUFACTURER_TABLE_EMPTY: 'There are no manufacturers loaded or added. \n Create a Manufacturer to add a Manufacturer to Bill Only Management.',
  MANUFACTURERS_CREATE_NEW_ACTION: 'Create Manufacturer',
  CREATE_MANUFACTURER_PAGE_HEADER: 'Create Manufacturer',
  MANUFACTURER_FORM_NAME: 'Manufacturer Name',
  CREATE_MANUFACTURER_SUBMIT_BTN: 'Save Manufacturer',
  CREATE_MANUFACTURER_SUCCESS: 'Manufacturer was successfully created.',
  CREATE_MANUFACTURER_ERROR: 'Manufacturer could not be added.',
  MANUFACTURER_NOT_UNIQUE_ERROR: 'A manufacturer with the entered name already exists.',
  MANUFACTURER_DETAILS_LOAD_ERR: 'There was an issue loading this manufacturer. Please try again. If the issue persists, please contact 877-774-4488.',
  MANUFACTURER_EDIT_HEADER: 'Edit Manufacturer',
  MANUFACTURER_EDIT_SUCCESS: 'Manufacturer was successfully updated.',
  MANUFACTURER_EDIT_FAILURE: 'Manufacturer could not be updated. Please try again. If the issue persists, please contact 877-774-4488.',

  // Hospital Manufacturer Ids
  HOSPITAL_MANUFACTURER_ID_NAME_LABEL: 'Hospital Vendor ID',
  HOSPITAL_MANUFACTURER_ID_LIST_HEADER_LABEL: 'Hospital Vendor IDs',
  HOSPITAL_MANUFACTURER_ID_SITE_NAME_LABEL: 'Site Name',
  HOSPITAL_MANUFACTURER_ID_SITE_ID_LABEL: 'Site ID',
  HOSPITAL_MANUFACTURER_ID_TABLE_EMPTY: 'There are no hospital vendor IDs loaded or added.\n'
    + 'Create a Hospital Vendor ID to add a hospital vendor ID to Bill Only Management.',
  HOSPITAL_MANUFACTURER_ID_CREATE_NEW_ACTION: 'Create Hospital Vendor ID',
  CREATE_HOSPITAL_MANUFACTURER_ID_PAGE_HEADER: 'Create Hospital Vendor ID',
  CREATE_HOSPITAL_MANUFACTURER_ID_SUBMIT_BTN: 'Save Hospital Vendor ID',
  CREATE_HOSPITAL_MANUFACTURER_ID_SUCCESS: 'Hospital Vendor ID was successfully created.',
  CREATE_HOSPITAL_MANUFACTURER_ID_ERROR: 'Hospital Vendor ID could not be added.',
  CREATE_MANUFACTURER_ID_NO_ASSOCIATIONS_ERROR: 'A Hospital Vendor ID must have at least one association to be created.',
  HOSPITAL_MANUFACTURER_NOT_UNIQUE_ERROR: (names: string[]) =>
    `A Hospital Vendor ID exists already for the following manufacturer(s): ${names.join(', ')}`,
  CREATE_HOSPITAL_MANUFACTURER_ID_ADD_ASSOCIATED_MANUFACTURER_BUTTON: 'Add Associated Manufacturer',
  CREATE_HOSPITAL_MANUFACTURER_ID_SUBFORM_HEADER: 'Add Hospital Vendor ID',
  CREATE_HOSPITAL_MANUFACTURER_ID_MANUFACTURER: 'Manufacturer',
  CREATE_HOSPITAL_MANUFACTURER_ID_TABLE_EMPTY: 'There are no Associated Manufacturers with this Hospital Vendor ID. \n Please add an Associated Manufacturer.',
  EDIT_HOSPITAL_MANUFACTURER_ID_HOSPITAL_LABEL: 'Hospital',
  EDIT_HOSPITAL_MANUFACTURER_ID_MANUFACTURER_LABEL: 'Manufacturer',
  EDIT_HOSPITAL_MANUFACTURER_ID_PAGE_HEADER: 'Edit Hospital Vendor ID',
  EDIT_HOSPITAL_MANUFACTURER_ID_ERROR: 'Hospital Vendor ID could not be updated.',
  EDIT_HOSPITAL_MANUFACTURER_ID_SUCCESS: 'Hospital Vendor ID was successfully updated.',

  // Requisitions
  REQ_ARCHIVED: 'Archived Requisitions',
  REQ_HEADER: 'Requisitions',
  REQ_STATUS: 'Status',
  REQ_CASE_DATE: 'Case Date',
  REQ_HOSPITAL: 'Hospital',
  REQ_DEPARTMENT: 'Department',
  REQ_DEPT: 'Dept.',
  REQ_MANUFACTURER: 'Manufacturer',
  REQ_MRN: 'MRN',
  REQ_VENDOR_REPRESENTATIVE: 'Vendor Representative',
  REQ_VALUE: 'Value',
  REQ_STATUS_REASON: 'Reason',
  REQ_COMPLETION_DATE: 'Completion Date',
  REQ_DATE_CREATED: 'Date Created',
  REQ_DATE_APPROVED: 'Date Approved',
  REQ_DATE_EXPORTED: 'Date Exported',
  REQ_PO_NUM: 'PO No.',
  REQ_ID: 'Document No.',
  REQ_RETURN_REASON: 'Returned Reason',
  REQ_DECLINED_REASON: 'Reason',
  REQS_AWAITING_YOUR_ACTION_HEADER: 'Awaiting Your Action',
  REQS_ARCHIVED_HEADER: 'All Archived Requisitions',
  REQS_APPROVED_HEADER: 'All Approved Requisitions',
  REQS_DECLINED_HEADER: 'All Declined Requisitions',
  REQS_PROCESS_PROCESSING_HEADER: 'All Pending Requisitions',
  REQUISITIONS_ARCHIVED_EMPTY: 'No Archived Requisitions',
  REQUISITIONS_APPROVER_AWAITING_ACTION_EMPTY: 'No Requisitions Awaiting Your Action',
  REQUISITIONS_APPROVER_APPROVED_EMPTY: 'No Approved Requisitions',
  REQUISITIONS_APPROVER_DECLINED_EMPTY: 'No Declined Requisitions',
  REQUISITIONS_APPROVER_PROCESSING_EMPTY: 'No Pending Requisitions',
  REQUISITIONS_CASE_DATE_CHANGED_MODAL_TEXT: 'By updating the following information, requisition products and their corresponding prices may be affected.' +
    '\n\nContinue to update this information.',
  REQS_TAB_ALL: 'All',
  REQS_TAB_AWAITING: 'Awaiting',
  REQS_TAB_PENDING: 'Pending',
  REQS_TAB_APPROVED: 'Approved',
  REQS_TAB_DECLINED: 'Declined',
  REQS_TAB_ARCHIVED: 'Archived',

  // - Req Details
  REQ_UPDATE_PRODUCTS_LINK: 'Update Products',
  REQ_VIEW_HEADER: 'Requisition',
  REQ_VIEW_DOCUMENT_NUMBER: 'Document Number',
  REQ_DETAILS_LOADING_REQ: 'Loading Requisition…',
  REQ_DETAILS_APPROVE: 'Approve Requisition',
  REQ_DETAILS_DECLINE: 'Decline',
  REQ_DETAILS_RETURN_TO_VENDOR: 'Return to Vendor',
  REQ_DETAILS_EDIT_BUTTON: 'Edit Information',
  REQ_DETAILS_VENDOR_REP_LABEL: 'Vendor Representative',
  REQ_DETAILS_CREATED_DATE: 'Date Submitted',
  REQ_DETAILS_ALREADY_ADDRESSED_ERR: 'This requisition was already addressed.',
  REQ_DETAILS_RETURN_TO_INVALID_VENDOR_ERR: 'This requisition cannot be returned to the vendor. Please check the vendor\'s user account permissions.' +
    'If you need help, please contact 877-774-4488.',
  REQ_DETAILS_APPROVAL_ERROR: 'There was a problem approving the requisition. Please try again. If the issue persists, please contact 877-774-4488',
  REQ_DETAILS_RESUBMIT_BUTTON: 'Submit Requisition',
  ARCHIVE_REQ_SUCCESS: 'Requisition was successfully archived.',
  UNARCHIVED_REQ_SUCCESS: 'Requsition was Successfully Unarchived.',

  MANUALLY_ARCHIVE_REQ_BUTTON: 'Archive Requisition',
  MANUALLY_UNARCHIVE_REQ_BUTTON: 'Un-archive Requisition',
  MANUALLY_ARCHIVE_REQ_CONFORMATION: 'Yes, archive requisition',
  MANUALLY_UNARCHIVE_REQ_CONFORMATION: 'Yes, un-archive requisition',
  MANUALLY_ARCHIVE_REQ_MODAL_QUESTION: 'Are you sure you want to archive this requisition?',
  MANUALLY_UNARCHIVE_REQ_MODAL_QUESTION: 'Are you sure you want to return this requisition to a completed status instead of an archived status?',
  MANUALLY_ARCHIVE_REQ_MODAL_INFO: 'Please note that the requisition will move off the "requisitions" page onto the "Archived Requisitions" page"',
  REQ_DETAILS_APPROVAL_SUCCESS: 'Requisition was successfully approved.',
  REQ_DETAILS_COST_CENTER_REQUIRED_ERROR: 'Cost Center must be added for the requisition to be approved.',
  // REQ_DETAILS_ITEM_IDS_REQUIRED_ERROR:
  // 'There are products on this requisition that do not have an assigned Item ID. Assign an Item ID to these products before approving this requisition.',
  REQ_DETAILS_PRODUCT_CATEGORIES_REQUIRED_ERROR: 'There are products on this requisition that do not have an assigned product category. Assign product categories to these products before approving this requisition.', // eslint-disable-line max-len
  REQ_DETAILS_PO_NUMBER: 'Requisition PO No.',

  // - - Req Decline Modal
  REQ_DETAILS_DECLINE_ERROR: 'There was a problem declining the requisition. Please try again. If the issue persists, please contact 877-774-4488',
  REQ_DETAILS_DECLINE_SUCCESS: 'Requisition was successfully declined.',
  REQ_DETAILS_DECLINE_MODAL_DESC: 'Decline Requisition',
  REQ_DECLINE_MODAL_REASON: 'Decline Reason',
  REQ_DECLINE_MODAL_REASON_PLACEHOLDER: 'Describe the reason why the requisition is being declined',
  REQ_DECLINE_MODAL_SUBMIT_BUTTON: 'Continue',

  // - - Req Return Modal
  REQ_RETURN_SUCCESS: 'Requisition was successfully returned to vendor.',
  REQ_RETURN_ERROR: 'There was an issue returning this requisition. Please try again. If the issue persists, please contact 877-774-4488.',
  REQ_RETURN_DESC: 'Return to Vendor',
  REQ_RETURN_REASON_LABEL: 'Return Reason',
  REQ_RETURN_REASON_PLACEHOLDER: 'Describe the reason why the requisition is being returned',
  REQ_RETURN_SUBMIT_BUTTON: 'Continue',

  // - - Req Notes
  REQ_NOTES_SECTION_HEADER: 'Notes',
  REQ_NOTES_NO_CONTENT: 'No updates.',
  REQ_NOTES_DATE_LABEL: 'Date',
  REQ_NOTES_ACTION_LABEL: 'Action Taken',
  REQ_NOTES_COMMENT_LABEL: 'Comment',
  REQ_NOTES_USER_LABEL: 'User',
  REQ_NOTES_ACTION_COMMENT: (actionTaken: string, userRole?: string) => (userRole ? `${actionTaken} by ${userRole}` : actionTaken),

  // - Create + Edit Req - Products
  REQ_PRODUCT_FORM_HEADER: 'Add Product',
  REQ_PRODUCT_ADD_BTN: 'Add Product',
  REQ_PRODUCT_SEARCH_PLACEHOLDER: 'Search using Catalog Number',

  // - Create Req
  REQ_CREATE_HEADER: 'Create a Requisition',
  REQ_CASE_INFO_HEADER: 'Case/Patient Information',
  REQ_CASE_INFO_STEPPER: 'Case/Patient Info',
  REQ_PRODUCT_INFO_STEPPER: 'Product Info',
  REQ_PRODUCT_INFO_HEADER: 'Product Information',
  REQ_BILLING_INFO_HEADER: 'Billing Information',
  REQ_PROCEDURE_INFO_STEPPER: 'Procedure Info',
  REQ_PROCEDURE_INFO_HEADER: 'Procedure Information',
  REQ_REVIEW_STEPPER: 'Review & Submit',
  REQ_PRODUCTS_TO_BILL_LABEL: 'Products to Bill',
  REQ_CREATE_ACTION: 'Create Requisition',
  REQ_CASE_PATIENT_SEARCH_BUTTON: 'Search',
  REQ_CASE_PATIENT_SEARCH_LABEL: 'Add Patient by MRN',
  REQ_CASE_PATIENT_SEARCH_PLACEHOLDER: 'Search with Full MRN',
  REQ_CASE_PATIENT_REMOVE_PATIENT_BUTTON: 'Change Patient',
  REQ_CASE_PATIENT_OPEN_CREATE_PATIENT_FORM_BUTTON: 'Add a new patient',
  REQ_CASE_PATIENT_CREATE_PATIENT_BUTTON: 'Add new patient',
  REQ_CASE_PATIENT_USE_EXISTING_PATIENT_BUTTON: 'Use existing patient',
  REQ_CASE_PATIENT_CREATE_PATIENT_HEADER: 'Create a Patient',
  REQ_CASE_PATIENT_CREATE_PATIENT_SUCCESS: 'Patient was successfully added',
  REQ_CASE_PATIENT_CREATE_PATIENT_TOO_LONG: 'cannot be longer than 50 characters',
  REQ_CASE_PATIENT_CREATE_PATIENT_FAILED_ERR: 'Patient cannot be added, please try again.',
  REQ_CASE_PATIENT_NOT_FOUND_MSG: 'Patient Not Found',
  REQ_CASE_PATIENT_INFO: 'Patient Information',
  REQ_CASE_PATIENT_ERR_MRN_REQUIRED: 'Enter a valid MRN',
  REQ_CASE_PATIENT_ERR_MRN_LENGTH: 'MRN has a limit of 50 characters',
  REQ_CASE_PATIENT_ERR_MRN_DUPLICATE: 'MRN is already in system',
  REQ_CASE_PATIENT_ERR_PATIENT: 'Patient must be added',
  REQ_CASE_PATIENT_ERR_CASE_NUM_REQUIRED: 'Case Number is required',
  REQ_CASE_PATIENT_ERR_CASE_NUM_CHARS: 'Limit of 50 chars.; No special chars.',
  REQ_CASE_PATIENT_ERR_CASE_DATE_REQUIRED: 'Case Date is required',
  REQ_CASE_PATIENT_ERR_CASE_DATE_INVALID: 'Enter valid date',
  REQ_CASE_PATIENT_ERR_CASE_DATE_BOUNDS: 'Date out of bounds',
  REQ_CAP_SEARCH_HEADER: 'Find and Select Your Capitation',
  REQ_CAP_SEARCH_RESULTS_HEADER: 'Available Capitations',
  REQ_CAP_SEARCH_OPEN_BUTTON: 'Add Capitations to Products',
  REQ_CAP_SEARCH_APPLY_BUTTON: 'Apply Cap to Req',
  REQ_CAP_NAME: 'Capitation Name',
  REQ_CAP_SAVING_AMOUNT: 'Cost Saving Amount',
  REQ_CAP_SAVING_PERCENT: 'Cost Saving %',
  REQ_CAP_PRICE: 'Capitation Price',
  REQ_CAP_REMOVE_BTN: 'Remove Capitation',
  REQ_CAP_REMOVED_MSG: 'Capitation has been removed. Products are still on requisition.',
  REQ_CAP_PRODUCT_REMOVE_BTN: 'Remove Product from Capitation',
  REQ_CAP_PRODUCT_REMOVED_MSG: 'Product has been removed from capitation. Product is still on the requisition.',
  REQ_PRODUCT_HOSPITAL: 'Hospital',
  REQ_PRODUCT_CATALOG_NUM: 'Catalog Number',
  REQ_PRODUCT_DESCRIPTION: 'Description',
  REQ_PRODUCT_SERIAL_NUM: 'Serial Number',
  REQ_PRODUCT_LOT_NUM: 'Lot Number',
  REQ_PRODUCT_QUANTITY: 'Quantity',
  REQ_PRODUCT_QUANTITY_UOM: 'QUoM',
  REQ_PRODUCT_QUANTITY_UOM_FULL: 'Quantity Unit of Measure (QUoM)',
  REQ_PRODUCT_UOM: 'UoM',
  REQ_PRODUCT_UOM_FULL: 'Unit of Measure (UoM)',
  REQ_PRODUCT_ITEM_ID: 'Item ID',
  REQ_PRODUCT_PRODUCT_CATEGORY: 'Product Category',
  REQ_PRODUCT_CONTRACT_PRICE: 'Contract Price',
  REQ_PRODUCT_CONTRACT_IDENTIFIER: 'Contract ID',
  REQ_PRODUCT_PRICE: 'Requisition Price',
  REQ_PRODUCT_LINE_TOTAL: 'Line Total',
  REQ_PRODUCT_SUBTOTAL: 'Subtotal',
  REQ_PRODUCTS_TOTAL: 'Grand Total',
  REQ_PRODUCT_BRAND: 'Brand',
  REQ_PRODUCT_PRODUCT_TYPE: 'Product Type',
  REQ_PRODUCT_TRANSACTION_TYPE: 'Transaction Type',
  REQ_PRODUCT_WASTED_DISCOUNT: 'Wasted or opened and not used',
  REQ_PRODUCT_TRIAL_DISCOUNT: 'Trial',
  REQ_PRODUCT_EXPLANT_DISCOUNT: 'Explant',
  REQ_PRODUCT_DISCOUNT: 'Discount',
  REQ_PRODUCT_DISCOUNT_PERCENTAGE: 'Discount Percentage',
  REQ_PRODUCT_DISCOUNT_REQUIRED_ERROR: 'Discount Percentage is required',
  REQ_PRODUCT_DISCOUNT_RANGE_ERROR: 'Discount should be between 0 and 100 percent',
  REQ_PRODUCT_REMOVE_BTN: 'Remove Product',
  REQ_PRODUCT_EDIT_BTN: 'Edit Product',
  REQ_SUBMIT_BUTTON_LABEL: 'Submit for Approval',
  REQ_NO_APPROVAL_ROUTE_ERROR: 'Unable to submit requisition for this hospital and department. Please contact us at 877-774-4488 for help.',
  REQ_SUBMISSION_SUCCESS: 'Requisition was successfully created.',
  REQ_SUBMISSION_ERROR: 'An error prevented this requisition from being properly submitted. Please try again or contact us at 877-774-4488 if the issue persists.',
  REQ_SUBMISSION_VALIDATION_ERROR: 'Please fill in all fields in order to submit this requisition.',
  REQ_PRODUCT_FETCH_PRODUCTS_ERROR: 'An error occurred finding products for the selected manufacturer. Please try again or contact us at 877-774-4488 if the issue persists.',
  FETCH_MANUFACTURERS_ERROR: 'An error occurred finding manufacturers. Please try again or contact us at 877-774-4488 if the issue persists.',
  FETCH_VENDORS_ERROR: 'An error occurred finding vendors. Please try again or contact us at 877-774-4488 if the issue persists.',
  REQ_PRODUCT_ADD_PRODUCT_ERROR: 'An error occurred adding the product to the requisition. Please try again or contact us at 877-774-4488 if the issue persists.',
  REQ_PRODUCT_FETCH_CONTRACTS_ERROR: 'An error occurred finding contract information for the selected manufacturer. '
  + 'Please try again or contact us at 877-774-4488 if the issue persists.',
  REQ_PRODUCT_MINIMUM_ERROR: 'You have not added any products to the requisition. Please add at least one (1) to proceed.',
  REQ_PRODUCT_MAXIMUM_ERROR: (maximumProductsPerRequisition: number) => `You have reached the maximum number of products (${maximumProductsPerRequisition}) for one requisition.`,
  REQ_HOSPITAL_FETCH_ERROR: 'An error occurred that prevented access to hospital information. Please refresh the page to retry or contact 877-774-4488 if the issue persists.',

  // - Req Drafts
  REQ_EDIT_DRAFT_HEADER: (id: number) => `Requisition Draft #${id}`,
  REQ_SAVE_DRAFT_SUCCESS: 'Your requisition was saved as a draft.',
  REQ_UPDATE_DRAFT_SUCCESS: 'Your draft was successfully updated.',
  REQ_SAVE_DRAFT_ERROR: 'An error prevented this draft from being properly saved. Please try again or contact us at 877-774-4488 if the issue persists.',
  REQ_SAVE_DRAFT_VALIDATION_ERROR: 'Please fill in Hospital and Department in order to save this draft.',
  REQ_DRAFT_DISCARD_BUTTON: 'Discard Draft',
  REQ_DRAFT_DISCARD_WARNING: 'Completing this action will completely remove this draft from the system. Continue?',
  REQ_DRAFT_DISCARD_SUCCESS: 'Draft successfully deleted.',
  REQ_DRAFT_DISCARD_ERROR: 'An error prevented this draft from being properly deleted. Please try again or contact us at 877-774-4488 if the issue persists.',

  // - Edit Req
  REQ_EDIT_HEADER: 'Edit Requisition Information',
  REQ_EDIT_SUCCESS: 'Information was successfully updated.',
  REQ_EDIT_NO_SUB_COST_CENTER: 'The selected Cost Center has no Sub Cost Centers.',
  REQ_EDIT_SELECT_COST_CENTER: 'Select a Cost Center first.',
  REQ_EDIT_ERROR: 'An error prevented this requisition from being updated. Please try again or contact us at 877-774-4488 if the issue persists.',

  // - Edit Req Products
  REQ_EDIT_PRODUCTS_HEADER: 'Update Requisition Products',
  REQ_EDIT_PRODUCTS_REQ_INFO_HEADER: 'Requisition Information',
  REQ_EDIT_PRODUCTS_PRODUCT_INFO_HEADER: 'Product Information',
  REQ_EDIT_PRODUCTS_SUCCESS: 'Information was successfully updated.',
  REQ_EDIT_PRODUCTS_ERROR: 'An error prevented this requisition from being updated. Please try again or contact us at 877-774-4488 if the issue persists.',
  REQ_EDIT_TYPE_TO_FILTER: 'type to filter list',
  REQ_EDIT_NO_PERMISSIONS: 'You do not have permission to edit this requisition.',
  REQ_EDIT_PRODUCT_MODAL_TITLE: 'Edit Product',
  REQ_EDIT_PRODUCT_MODAL_SUBMIT_BTN: 'Save',
  REQ_EDIT_PRODUCT_CATEGORY_GET_FAIL_ERROR: 'An error prevented access to product categories information. Please try again or contact us at 877-774-4488 if the issue persists.',
  REQ_EDIT_PRODUCT_HOSP_PROD_GET_FAIL_ERROR: 'An error prevented access to product information. Please try again or contact us at 877-774-4488 if the issue persists.',
  REQ_EDIT_PRODUCT_PATCH_FAIL_ERROR: 'An error prevented the product from being updated. Please try again or contact us at 877-774-4488 if the issue persists.',

  // - Create/Edit Req Create New Product Modal
  REQ_CREATE_HOSP_PROD_MODAL_TITLE: 'Create a New Product',
  REQ_CREATE_HOSP_PROD_MODAL_ADD_BTN: 'Add Product',

  FORM_CONTROL_LABEL_EMAIL: 'Email',
  FORM_CONTROL_LABEL_FIRST_NAME: 'First Name',
  FORM_CONTROL_LABEL_MIDDLE_NAME: 'Middle',
  FORM_CONTROL_LABEL_LAST_NAME: 'Last Name',
  FORM_CONTROL_LABEL_PHONE_NUMBER: 'Contact Phone',
  FORM_CONTROL_LABEL_HOSPITAL: 'Hospital',
  FORM_CONTROL_LABEL_DEPARTMENT: 'Department',
  FORM_CONTROL_LABEL_DEPARTMENTS: 'Department(s)',
  FORM_CONTROL_LABEL_ASSOCIATED_DEPARTMENTS: 'Associated Department(s)',
  FORM_CONTROL_LABEL_PROCEDURE: 'Procedure Performed',
  FORM_CONTROL_LABEL_PHYSICIAN: 'Physician',
  FORM_CONTROL_LABEL_PATIENT_DOB: 'DOB',
  FORM_CONTROL_LABEL_PATIENT_MRN: 'MRN',
  FORM_CONTROL_LABEL_PATIENT_NAME: 'Name',
  FORM_CONTROL_LABEL_PATIENT_GENDER: 'Gender',
  FORM_CONTROL_LABEL_CASE_NUMBER: 'Case Number',
  FORM_CONTROL_LABEL_CASE_DATE: 'Case Date',
  FORM_CONTROL_LABEL_MANUFACTURER: 'Manufacturer',
  FORM_CONTROL_LABEL_MANUFACTURERS: 'Manufacturer(s)',
  FORM_CONTROL_LABEL_ADD_ASSOCIATIONS: 'Add Associations',
  FORM_CONTROL_LABEL_PASSWORD: 'Password',
  FORM_CONTROL_LABEL_TEST_ACCOUNTS_ONLY: 'Test Accounts Only',
  FORM_CONTROL_LABEL_TEMP_PASSWORD_PLACEHOLDER: 'Set a password.',
  FORM_CONTROL_LABEL_LOOKBACK_PERIOD_DESCRIPTOR: 'Order date/time sent lookup days',
  FORM_CONTROL_LABEL_ELIGIBILITY_DATE_DESCRIPTOR: 'Order date/time sent greater than',

  // Products To Bill
  REQ_PRODUCTS_TO_BILL_QUANTITY: 'Qty',
  REQ_PRODUCTS_TO_BILL_SEQUENCE_NUMBER: '#',
  REQ_PRODUCTS_TO_BILL_CATALOG_NUMBER: 'Catalog No.',
  REQ_PRODUCTS_TO_BILL_SERIAL_NUMBER: 'Serial No.',
  REQ_PRODUCTS_TO_BILL_LOT_NUMBER: 'Lot No.',
  REQ_PRODUCTS_TO_BILL_INDV_TABLE_HEADER: 'Individual Products',
  REQ_PRODUCTS_TO_BILL_CAP_TABLE_HEADER: 'Capitations',
  REQ_PRODUCTS_TO_BILL_COLLAPSE_ALL: 'Collapse All',
  REQ_PRODUCTS_TO_BILL_EXPAND_ALL: 'Expand All',
  REQ_PRODUCTS_TO_BILL_COLLAPSE_SECTION: 'Collapse Section',
  REQ_PRODUCTS_TO_BILL_EXPAND_SECTION: 'Expand Section',
  REQ_PRODUCTS_TO_BILL_EMPTY: 'There are no products added.',

  // Req Apply Capitations
  CAPITATIONS_MODAL_TABLE_EMPTY: 'No Results Found',

  // Req Tool tips
  REQ_ID_TOOLTIP: 'Req ID:',
  REQ_PRODUCTS_TO_BILL_ID_TOOLTIP: 'Req Product ID:',

  // Capitations
  CAPITATIONS_TABLE_EMPTY: 'No Capitations Available',

  // Cost Centers
  COST_CENTER_LABEL: 'Cost Center',
  COST_CENTERS_LABEL: 'Cost Centers',
  COST_CENTERS_TABLE_EMPTY: 'There are no cost centers loaded or added. Create a Cost Center to add a cost center to Bill Only Management.',
  COST_CENTERS_HOSPITAL_NAME: 'Hospital Name',
  COST_CENTERS_DEPARTMENT_NAME: 'Department Name',
  COST_CENTER_ID: 'Cost Center ID',
  SUB_COST_CENTER_LABEL: 'Sub Cost Center',
  SUB_COST_CENTER_TABLE_HEADER: 'Sub Cost Centers',
  SUB_COST_CENTER_ID: 'Sub Cost Center ID',
  COST_CENTERS_CREATE_NEW_ACTION: 'Create Cost Center',
  CREATE_COST_CENTER_PAGE_HEADER: 'Create Cost Center',
  SUB_COST_CENTER_REMOVE_BTN: 'Remove Sub Cost Center',
  CREATE_COST_CENTER_SUBMIT_BTN: 'Save Cost Center',
  CREATE_COST_CENTER_SUCCESS: 'Cost Center was successfully created.',
  CREATE_COST_CENTER_ERROR: 'Cost Center could not be added.',
  COST_CENTER_NOT_UNIQUE_ERROR: 'Cost Center Name must be unique within a given department.',
  COST_CENTER_IDENTIFIER_NOT_UNIQUE_ERROR: 'Cost Center ID must be unique within a given department.',
  COST_CENTER_FORM_NAME: 'Cost Center',
  COST_CENTER_FORM_IDENTIFIER: 'Cost Center ID',
  SUB_COST_CENTER_FORM_NAME: 'Sub Cost Center',
  SUB_COST_CENTER_FORM_IDENTIFIER: 'Sub Cost Center ID',
  SUB_COST_CENTER_TABLE_EMPTY: 'There are no sub cost centers for this cost center. Add a Sub Cost Center to this Cost Center.',
  SUB_COST_CENTER_SUBFORM_HEADER: 'Add Sub Cost Center',
  COST_CENTER_ADD_SUB_COST_CENTER_BUTTON: 'Add Sub Cost Center',
  COST_CENTER_FORM_DUPLICATE_SUB_COST_CENTER_NAME_ERROR: 'Sub Cost Center Name must be unique within a cost center.',
  COST_CENTER_FORM_DUPLICATE_SUB_COST_CENTER_IDENTIFIER_ERROR: 'Sub Cost Center ID must be unique within a cost center.',
  EDIT_COST_CENTER_PAGE_HEADER: 'Edit Cost Center',
  EDIT_COST_CENTER_LOAD_ERROR: 'There was an issue loading this cost center. Please try again. If the issue persists, please contact 877-774-4488.',
  EDIT_COST_CENTER_SUCCESS: 'Cost Center was successfully updated.',
  EDIT_COST_CENTER_FAILURE: 'Cost Center could not be updated. Please try again. If the issue persists, please contact 877-774-4488.',

  // Archive Cost Centers
  ARCHIVE_COST_CENTER_BTN: 'Archive Cost Center',
  ACTIVATE_COST_CENTER_BTN: 'Activate Cost Center',
  ARCHIVE_COST_CENTER_SUCCESS: 'Cost Center was successfully archived.',
  ACTIVATE_COST_CENTER_SUCCESS: 'Cost Center was successfully activated.',
  ARCHIVE_COST_CENTER_ERROR: 'Cost Center could not be updated.',
  ARCHIVE_COST_CENTER_MODAL_HEADER: 'Archive this Cost Center',
  ARCHIVE_COST_CENTER_MODAL_TEXT: 'By archiving this cost center, it will no longer be available for selection when approving new requisitions. Existing requisitions will not be affected and archived cost centers can be reactivated.\n\n Any changes not saved will be lost upon archiving.\n\nContinue to archive this cost center?', // eslint-disable-line max-len
  ACTIVE_SUB_COST_CENTER_TABLE_EMPTY: 'No active Sub Cost Centers.',
  ARCHIVED_SUB_COST_CENTER_TABLE_EMPTY: 'No archived Sub Cost Centers.',
  ARCHIVE_SUB_COST_CENTER_BTN: 'Archive Sub Cost Center',
  ACTIVATE_SUB_COST_CENTER_BTN: 'Activate Sub Cost Center',
  ARCHIVE_SUB_COST_CENTER_ERROR: 'Sub Cost Center(s) could not be updated.',

  // Approval Routes
  APPROVAL_ROUTES_TABLE_EMPTY: 'No Approval Routes Available',

  // Physicians
  PHYSICIANS_LIST_HEADER_LABEL: 'Physicians',
  PHYSICIAN_NAME_LABEL: 'Name',
  PHYSICIAN_ID_LABEL: 'Physician ID',
  PHYSICIAN_DEPARTMENTS_LABEL: 'Department(s)',
  PHYSICIAN_TABLE_EMPTY: 'There are no physicians loaded or added. \n Create a Physician to add a physician to Bill Only Management.',
  PHYSICIANS_CREATE_NEW_ACTION: 'Create Physician',
  PHYSICIAN_NOT_UNIQUE_ERROR: (hospitalNames: string[]) => `Physician ID was not unique at the following hospital(s): ${joinArgs(...hospitalNames)}`,
  PHYSICIAN_HOSPITAL_SUBFORM_HEADER: 'Add Hospital',
  PHYSICIAN_FORM_HOSPITAL_ID: 'Hospital',
  PHYSICIAN_FORM_PHYSICIAN_ID: 'Physician ID',
  PHYSICIAN_FORM_DEPARTMENT_ID: 'Department(s)',
  PHYSICIAN_ADD_HOSPITAL_BUTTON: 'Add Associated Hospital',

  // - Create Physician
  CREATE_PHYSICIAN_PAGE_HEADER: 'Create Physician',
  CREATE_PHYSICIAN_FIRST_NAME: 'First Name',
  CREATE_PHYSICIAN_MIDDLE_INITIAL: 'Middle Initial',
  CREATE_PHYSICIAN_SUFFIX: 'Suffix',
  CREATE_PHYSICIAN_FIRST_NAME_PLACEHOLDER: 'Enter First Name',
  CREATE_PHYSICIAN_LAST_NAME_PLACEHOLDER: 'Enter Last Name',
  CREATE_PHYSICIAN_MIDDLE_INITIAL_PLACEHOLDER: 'Middle Initial',
  CREATE_PHYSICIAN_SUFFIX_PLACEHOLDER: 'Jr, Senior, Third',
  CREATE_PHYSICIAN_LAST_NAME: 'Last Name',
  CREATE_PHYSICIAN_SUBMIT_BTN: 'Save Physician',
  CREATE_PHYSICIAN_ERROR: 'Physician could not be added.',
  CREATE_PHYSICIAN_SUCCESS: 'Physician was created successfully.',
  CREATE_EDIT_PHYSICIAN_TABLE_EMPTY: 'There are no hospitals added. Add an associated hospital for this physician.',
  PHYSICIAN_NO_ASSOCIATIONS_ERROR: 'Physician must have Hospital and Department associations. Add an association, or archive the physician.',
  EDIT_PHYSICIAN_NO_ASSOCIATIONS_ERROR: 'Physician must have Department associations. To remove all departments, please click Cancel and delete the hospital.',
  PHYSICIAN_HOSPITAL_FETCH_ERROR: 'An error occurred that prevented access to hospital information. Please refresh the page to retry or contact 877-774-4488 if the issue persists.',

  PHYSICIAN_ASSOCIATIONS_ROW_REMOVE_TEXT: 'Remove Association',
  PHYSICIAN_ASSOCIATIONS_ROW_EDIT_TEXT: 'Edit Association',

  // - Edit Physician
  PHYSICIAN_FETCH_ERROR: 'There was an issue loading this physician. Please refresh the page to retry or contact 877-774-4488 if the issue persists.',
  EDIT_PHYSICIAN_PAGE_HEADER: 'Edit Physician',
  EDIT_PHYSICIAN_ERROR: 'Physician could not be updated.',
  EDIT_PHYSICIAN_SUCCESS: 'Physician was updated successfully.',
  EDIT_PHYSICIAN_ASSOCIATIONS_EDIT_MODAL_TITLE: 'Edit Hospital Association',
  EDIT_PHYSICIAN_ASSOCIATIONS_EDIT_MODAL_SUBMIT: 'Update Hospital Association',
  EDIT_PHYSICIAN_ASSOCIATIONS_EDIT_MODAL_DEPARTMENTS: 'Department(s) *',
  EDIT_PHYSICIAN_ASSOCIATIONS_EDIT_ADD_DEPARTMENTS_BUTTON: 'Add Department(s)',
  EDIT_PHYSICIAN_ASSOCIATIONS_HOSPITAL_FETCH_ERROR:
    'An error occurred finding departments for the selected hospital. Please try again or contact us at 877-774-4488 if the issue persists.',
  EDIT_PHYSICIAN_ASSOCIATIONS_EDIT_MODAL_TABLE_EMPTY: 'No Departments Added',
  EDIT_PHYSICIAN_ASSOCIATIONS_EDIT_MODAL_TABLE_ROW_REMOVE: 'Remove Department',
  EDIT_PHYSICIAN_ASSOCIATIONS_EDIT_MODAL_TABLE_HEADER: 'Departments',

  // Archive Physician
  ARCHIVE_PHYSICIAN_BTN: 'Archive Physician',
  ACTIVATE_PHYSICIAN_BTN: 'Activate Physician',
  ARCHIVE_PHYSICIAN_SUCCESS: 'Physician was successfully archived.',
  ACTIVATE_PHYSICIAN_SUCCESS: 'Physician was successfully activated.',
  ARCHIVE_PHYSICIAN_ERROR: 'Physician could not be updated.',
  ARCHIVE_PHYSICIAN_MODAL_HEADER: 'Archive this Physician',
  ARCHIVE_PHYSICIAN_MODAL_TEXT: 'By archiving this physician, they will no longer be available for selection on new requisitions. Existing requisitions will not be affected and archived physicians can be reactivated.\n\n Any changes not saved will be lost upon archiving.\n\nContinue to archive this physician?', // eslint-disable-line max-len

  // Users
  CREATE_HOSPITAL_USER_ACCOUNT: 'Create Hospital User Account',
  CREATE_NEW_HOSPITAL_USER: 'Create New Hospital User',
  CREATE_NEW_USER_BUTTON_LABEL: 'Create New User',
  CREATE_NEW_VENDOR_USER: 'Create New Vendor User',
  CREATE_VENDOR_USER_ACCOUNT: 'Create Vendor User Account',

  EDIT_VENDOR_USER_ACCOUNT: 'Edit Vendor User Account',
  VIEW_VENDOR_USER_ACCOUNT: 'View Vendor User Account',
  EDIT_HOSPITAL_USER_ACCOUNT: 'Edit Hospital User Account',
  EDIT_HOSPITAL_USER_ACCOUNT_SUCCESS: 'Hospital User was updated successfully.',
  CREATE_HOSPITAL_USER_ACCOUNT_SUCCESS: 'Hospital User was created successfully.',
  EDIT_VENDOR_USER_ACCOUNT_SUCCESS: 'Vendor User was updated successfully.',
  CREATE_VENDOR_USER_ACCOUNT_SUCCESS: 'Vendor User was created successfully.',
  VIEW_HOSPITAL_USER_ACCOUNT: 'View Hospital User Account',

  DEACTIVATE_USER_BTN: 'Deactivate User',
  ACTIVATE_USER_BTN: 'Activate User',
  DEACTIVATE_USER_SUCCESS: 'User was successfully deactivated.',
  ACTIVATE_USER_SUCCESS: 'User was successfully activated.',
  ARCHIVE_USER_ERROR: 'User could not be updated.',
  DEACTIVATE_USER_MODAL_HEADER: 'Deactivate this user account',
  DEACTIVATE_USER_MODAL_TEXT: 'By deactivating this user they will no longer be able to log in to the application under this email address. Deactivated users can be reactivated.\n\nAny changes not saved will be lost upon deactivation.\n\nContinue to deactivate this user?', // eslint-disable-line max-len

  USER_ASSOCIATIONS_ROW_REMOVE_TEXT: 'Remove Association',
  USER_ASSOCIATIONS_ROW_EDIT_TEXT: 'Edit Association',

  USER_ASSOCIATIONS_EDIT_MODAL_TITLE: 'Edit Hospital Association',
  USER_ASSOCIATIONS_EDIT_MODAL_SUBMIT: 'Update Hospital Association',
  USER_ASSOCIATIONS_EDIT_USER_ROLE: 'User Role',
  USER_ASSOCIATIONS_HOSPITAL_FETCH_ERROR: 'An error occurred finding departments for the selected hospital. Please try again or contact us at 877-774-4488 if the issue persists.',
  USER_ASSOCIATIONS_EDIT_MODAL_DEPT_TABLE_EMPTY: 'No Departments Added',
  USER_ASSOCIATIONS_EDIT_MODAL_MANU_TABLE_EMPTY: 'No Manufacturers Added',
  USER_ASSOCIATIONS_EDIT_MODAL_TABLE_ROW_REMOVE_DEPT: 'Remove Department',
  USER_ASSOCIATIONS_EDIT_MODAL_TABLE_ROW_REMOVE_MANUFACTURER: 'Remove Manufacturer',

  FORM_DISPLAY_HOSPITAL_ASSOCIATIONS: 'Hospital Associations',
  CREATE_EDIT_USER_NO_ASSOCIATIONS_ERROR: 'A user must have at least one Hospital association. Contact Support at 877-774-4488 if you need to disable this user.',
  EDIT_USER_ASSOCIATION_NO_ASSOCIATIONS_ERROR:
    'A user association must have Department and Manufacturer associations. '
    + 'To remove all departments and manufacturers for this hospital association, please click Cancel and delete the hospital association.',
  CREATE_UPDATE_USER_ERROR: 'User account could not be created or updated. Please contact support at 877-774-4488.',
  EDIT_USER_LOADING: 'Loading user…',
  EDIT_USER_FAILED_TO_LOAD: 'An error occurred trying to load the user\'s details.',
  EDIT_USER_NOT_ALLOWED_TO_UPDATE: 'You do not have permission to edit any user accounts at this time.',

  USER_HOSPITALS_LABEL: 'Hospitals',
  USER_FIRST_NAME_LABEL: 'First Name',
  USER_LAST_NAME_LABEL: 'Last Name',
  USER_EMAIL_LABEL: 'Username/Email',
  USER_PHONE_LABEL: 'Contact Phone',
  VENDOR_USERS: 'Vendor Users',
  HOSPITAL_USERS: 'Hospital Users',

  HOSPITAL_USERS_TABLE_EMPTY: 'No Hospital Users Available',
  VENDOR_USERS_TABLE_EMPTY: 'No Vendor Users Available',
  CREATE_VENDOR_USER_ASSOCIATIONS_TABLE_EMPTY: 'No Associations Added',

  // Autocomplete
  AUTOCOMPLETE_NO_OPTIONS: 'No Results Found',
  AUTOCOMPLETE_NEED_RESULTS: 'Type at least 3 characters to see results...',
  //   TODO: Remove create product-specific logic from autocomplete
  AUTOCOMPLETE_CANT_FIND_PRODUCT: 'Can\u0027t find your product?',
  AUTOCOMPLETE_CREATE_NEW_PRODUCT: 'Create a New Product',

  // Tables
  TABLE_PAGING_ITEM_RANGE: (start: number, end: number, itemCount: number) => `Showing ${start}-${end} out of ${itemCount} item(s)`,
  TABLE_PAGING_PAGE_PROGRESS: (currentPage: number, pageCount: number) => `${currentPage} of ${pageCount}`,

  // Error page
  ERROR_PAGE_TITLE: 'Oops, something went wrong…',
  ERROR_PAGE_TEXT: 'Please refresh your screen or try again later. \n\n If the error still persists, please contact support at 877-774-4488.',

  // Authentication
  AUTH_LOGOUT_MODAL_TITLE: 'Session Timeout Warning',
  AUTH_LOGOUT_MODAL_BODY: 'Your session will exit momentarily.',
  AUTH_LOGOUT_MODAL_EXIT_NOW: 'Exit Now',
  AUTH_LOGOUT_MODAL_STAY_LOGGED_IN: 'Stay Logged In',

  // Settings: Approval Routing Customization
  APPROVAL_ROUTING_CUSTOMIZATION: 'Approval Routing Customization',
  REQ_PRODUCT_APPROVAL_PREFERENCES: 'Requisition Product Approval Preferences',
  OFF_CONTRACT_PRODUCTS_LABEL_1: 'Off Contract Products',
  OFF_CONTRACT_PRODUCTS_LABEL_2: 'Products without a contract ID',
  CONTRACT_PRICE_CHANGES_LABEL: 'Contract Price Changes',
  NEW_PRODUCTS_LABEL: 'New Products',
  SKIP_BUYER: 'Skip buyer and route requisition to first approver',
  DO_NOT_PERMIT_IN_PRODUCT_SEARCH: 'Do not permit these products in requisition product search',
  DO_NOT_PERMIT: 'Do not permit',
  ROUTE_TO_BUYER: 'Route to buyer prior to first approvals for:',
  ZERO_PRICE_PRODUCTS: '$0 products',
  PRODUCTS_MISSING_ITEM_ID: 'Products missing item ID',
  TRIAL_PRODUCTS: 'Trial products',
  WASTED_PRODUCTS: 'Wasted products',
  EXPLANTED_PRODUCTS: 'Explanted products',
  SAVE_SETTINGS: 'Save Settings',
  EDIT_SETTINGS_SUCCESS: 'Your settings have been updated.',
  STATIC_PDF_MESSAGE: '**Please refer to the diagram below for Bill Only Management\'s default logic in place when none of the above preferences have been set, refer to this document',
  ASSOCIATED_HOSPITALS: 'Associated Hospitals',
  BILL_ONLY_WORKFLOW_DIAGRAM: 'View Diagram',
  SETTINGS_FETCH_ERROR: 'An error occurred finding settings for the selected hospital. Please try again or contact us at 877-774-4488 if the issue persists.',

  // Auto Archive
  AUTO_ARCHIVE: 'Auto Archive',
  AUTO_ARCHIVE_UPDATE_TITLE: 'Update Number of Days Until Archive',
  DAYS_UNTIL_ARCHIVE: '# Of Days Until Archive',
  AUTO_ARCHIVE_EMPTY_TABLE: 'There are no archived requisitions.',

  // Help center
  HELP_CENTER_HEADER: 'Help Center',
  HELP_CENTER_MANAGE_BUTTON: 'Manage Terms',
  HELP_CENTER_CONTACT_US: 'If you have application (technical) issues, please contact the QSight Support team at ',
  PRIVACY_POLICY_LABEL: 'Privacy Policy',
  ADDITIONAL_ASSISTANCE_HEADER: 'Need Additional Assistance?',
  ADDITIONAL_ASSISTANCE_PRIMARY_TEXT: 'Please contact your Bill Only Admin if you have any of the following issues:',
  MANAGE_TERMS_OF_USE_PAGE_HEADER: 'Manage Terms of Use',
  MANAGE_TERMS_OF_USE_EDITOR_LABEL: 'Terms of Use',
  MANAGE_TERMS_OF_USE_PREVIEW_HEADER: 'Terms of Use Preview:',
  MANAGE_TERMS_PLACEHOLDER_TEXT: 'Copy text from Word, click here, then paste.',
  MANAGE_TERMS_UPDATE_BUTTON: 'Update Terms of Use',
  QSIGHT_SUPPORT_NUMBER: '877-774-4488',
  QSIGHT_SUPPORT_EMAIL: 'support@qsight.net',

  // Interfaces
  INTERFACES_HEADER: 'Interfaces',
  TO_BE_EXPORTED: 'To be Exported',
  INTERFACE_EXCEPTION_TAB: 'Exceptions',
  INTERFACE_PENDING_EXPORT_HEADER: 'All Pending Exports',
  PAST_EXPORT_HEADER: 'All Past Exports',
  INTERFACE_EXCEPTIONS_HEADER: 'Exceptions',
  INTERFACE_NO_EXCEPTIONS: 'No Requisitions with Exceptions',
  INTERFACE_EXCEPTION_STATUS_BADGE: 'Exception',
  INTERFACE_NUMBER_OF_EXCEPTIONS: 'No. of Exceptions',
  INTERFACE_MANUFACTURER_ID: 'Vendor ID',
  INTERFACE_EDIT_PRODUCT_ERROR: 'Product could not be updated. Please try again. If the issue persists, please contact 877-774-4488.',
  INTERFACE_EDIT_PRODUCT_SUCCESS: 'Product successfully updated.',

  // Reports
  REPORTS_HEADER: 'Reports',
  FULL_PRODUCT_EXTRACT: 'Contract and Non-Contract Products',

  // Miscellaneous / Shared
  ACTIVE_TAB_LABEL: 'ACTIVE',
  ARCHIVED_TAB_LABEL: 'ARCHIVED',
  DEACTIVATED_TAB_LABEL: 'DEACTIVATED',
  MANUFACTURER_TAB_LABEL: 'Manufacturer',
  DEPARTMENT_TAB_LABEL: 'Department',
  REQUIRED_FIELD_MARK: '\u002a',
  NBSP: '\u00A0',
  EMPTY_FIELD_MARK: '\u2014',
  ADMIN: 'Admin',
  REQUISITIONS: 'Requisitions',
  DISMISS: 'Dismiss',
  STANDARD_ERROR: 'An error occurred. Please try again or contact us at 877-774-4488 if the issue persists.',
  HOSPITAL_FETCH_ERROR: 'An error occurred that prevented access to hospital information. Please refresh the page to retry or contact 877-774-4488 if the issue persists.',
  PROCEED_ACTION: 'Proceed to Next Step',
  FORM_SUBMIT_PROCESSING_BUTTON: 'Processing',
  LOADING_SPINNER_TITLE: 'Processing',
  SAVE_BUTTON_LABEL: 'Save',
  SAVE_CHANGES_BUTTON_LABEL: 'Save Changes',
  FILTER_LABEL: 'Filter',
  FILTER_CATEGORY_PLACEHOLDER: 'Select Category',
  CANCEL_MODAL_TEXT: 'If you leave this page, any changes made will be lost. \n \nContinue to exit or cancel to stay on the page. ',
  CANCEL_MODAL_TITLE: 'Attention!',
  CONTINUE_AND_EXIT: 'Continue & Exit',

  REQUIRED_ERROR: (name: string) => `${name} is required`,
  MIN_CHAR_LIMIT_ERROR: (limit: number): string => `Must contain at least ${limit} letters or numbers`,
  NOT_NUMERIC_ERROR: 'Must be numeric',
  ONLY_TWO_DECIMAL_ERROR: 'Only two decimal places allowed',
  NOT_IN_RANGE_ERROR: (min: number, max: number) => `Must be between ${min.toLocaleString()} and ${max.toLocaleString()}`,
  DOES_NOT_EXCEED_ERROR: (max: number) => `Must not exceed ${max.toLocaleString()}`,
  NOT_WHOLE_ERROR: 'Must be a whole number',
  EMAIL_NOT_UNIQUE_ERROR: 'A user with the entered email already exists.',
  INVALID_PHONE_ERROR: 'Please enter valid phone number',
  INVALID_EMAIL_ERROR: 'Please enter valid email',
  INVALID_PASSWORD_ERROR: 'This password does not meet password requirements. Must contain at least 8 characters and one of each of the following: uppercase letter, lowercase letter, number and symbol (!,@,$,^,*,+,etc.).', // eslint-disable-line max-len

  HOSPITAL_CONTACT_LABEL: 'Contact:',

  HOSPITAL_USER_ROLE_ADMIN: 'Admin',
  HOSPITAL_USER_ROLE_STAFF: 'Staff',
  HOSPITAL_USER_ROLE_SR: 'Standard Representative',
  HOSPITAL_USER_ROLE_CSR: 'Customer Service Representative',

  SEARCH_FIELD_INPUT_PLACEHOLDER_DEFAULT: 'Search',
  DROPDOWN_DEFAULT_PLACEHOLDER: 'Choose One',

  DATE_OUT_OF_BOUNDS_ERROR: 'Date out of bounds',
  TABLE_LOADING: 'Loading Data…',
  TERMS_OF_USE_HEADER: 'Terms of Use',
  ACKNOWLEDGE_AND_PROCEED: 'Acknowledge & Proceed',
  BACK: 'Back',
  EXIT: 'Exit',
  CANCEL: 'Cancel',
  CONTINUE: 'Continue',
  SEARCH: 'Search',
  OR: 'or',
});
